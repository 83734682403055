import React, { useEffect } from "react";
import { graphql } from "gatsby";
import SEO from "../components/global/seo";

// Fragments
import { MetaCardFragment, BlockZoneFragment } from "../GraphQl/queryFragments"; // eslint-disable-line

// Hooks, Querys & State
import { useAppContext } from "../state";

// Components
import { BlockZone } from "../components/pageBlocks/blockZone";

const IndexPage = ({ data }) => {
  const { pageBlocks } = data?.sanityHomePage?.main || {};
  const { metaDescription, metaTitle, metaKeywords, metaImage } =
    data?.sanityHomePage?.meta || {};
  const { setReverseHeader, setHiddenHeaderFooter } = useAppContext();

  useEffect(() => {
    setReverseHeader(false);
    setHiddenHeaderFooter(false);
  }, [setReverseHeader, setHiddenHeaderFooter]);

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
        metaImage={metaImage}
      />
      {pageBlocks && <BlockZone zoneBlocks={pageBlocks} />}
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  {
    sanityHomePage {
      meta {
        ...MetaCardFields
      }
      main {
        pageBlocks {
          ...BlockZoneFields
        }
      }
    }
  }
`;
